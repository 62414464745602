.view-data {
  font-size: 20px;

  &-diagramm {
    &-close-button {
      position: absolute;
      top: -101px;
      right: 64px;
      z-index: 2;
    }
  }

  &__calendar {
    position: fixed;
    bottom: -160px;
    z-index: 10000000;
  }
}

.react-date-picker {
  &-dropdown-top {
    z-index: 1000 !important;
    transform: translate3d(-58px, calc(-100% - 55px), 0) !important;
  }
}

.traffic-history-player {
  &-graph {
    position: fixed !important;
    left: 35px !important;
    top: 540px !important;
    box-shadow: none !important;
    div canvas {
      box-shadow: none !important;
      border: none;
    }
    button {
      box-shadow: none !important;
      border-radius: 3px;
      border: 1px solid #e2e2e2;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        justify-content: center;
      }
    }
    li {
      box-shadow: none !important;
    }
  }
}

.view-data-graph-container {
  box-shadow: none !important;
}
.correlation-control {
  &__button {
    position: absolute;
    bottom: 168px;
    left: 260px;
    pointer-events: auto;
  }

  &__calendar {
    position: absolute;
    bottom: 168px;
    left: 145px;
    border-radius: 3px;
    box-shadow: var(--box-shadow-button);
    pointer-events: auto;
  }
}
